import "./training-prices-card.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import { Pack } from "../../../contexts/shared/domain/Pack";

interface PropsTrainingPricesCard extends PropsWithChildren<any> {
  packs: Pack[];
  color: string;
}

const TrainingPricesCard: React.FC<PropsTrainingPricesCard> = (
  props: PropsTrainingPricesCard
) => {
  if (props.packs && props.packs.length < 1) return <></>;
  const { packs, color } = props;

  const packContent = [];

  if (packs) {
    packs.forEach((pack, i) => {
      if (pack.include.length !== 3) return;
      packContent.push(
        <div className={"m-pack__wrapper"} key={i}>
          <div className={"m-pack"}>
            <p className={"a-pack__title"}>{pack.name}</p>
            <div className={"m-card m-card__responsive "}>
              <div className={"m-card__content m-vertical-xl"} key={i}>
                <p className={"a-card__paragraph"}>{pack.include[0]}</p>
                <hr className={"a-separator"} />
                <p className={"a-card__paragraph"}>{pack.include[1]}</p>
                <hr className={"a-separator"} />
                <p className={"a-card__paragraph"}>{pack.include[2]}</p>
              </div>
              <div
                className={
                  "m-card m-card__footer m-card--no-shadow grid-70-30 grid-70-30--break-desktop grid-70-30--no-gap-desktop"
                }
                style={{
                  backgroundColor: `${color}`,
                  color: "#fff",
                }}
              >
                <div className={"m-card__discount"}>
                  <p className={"a-card__paragraph"}>{pack.offer}</p>
                  <p className={"a-card__paragraph"}>
                    Pago mensual: {pack.price}€ <br />
                    Pago trimestral: {pack.priceOffer}€ (
                    {Math.round((pack.priceOffer / 3) * 100) / 100}
                    €/mes) <br />
                    <small>*Domiciliación bancaria</small>
                  </p>
                </div>

                {pack.percentageDiscount && (
                  <div
                    className={"is-text-center"}
                    style={{ marginRight: "3em" }}
                  >
                    <p
                      className={
                        "a-card__paragraph a-card__paragraph-number is-fs-title-xl"
                      }
                    >
                      {pack.percentageDiscount
                        ? `-${pack.percentageDiscount}%`
                        : ``}
                    </p>
                    <p className={"a-card__paragraph"}>
                      <small>dcto. trimestral</small>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return <div className={"o-training-prices"}>{packContent}</div>;
};

export default TrainingPricesCard;
