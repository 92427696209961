import "./info-card.scss";

import * as React from "react";
import { PropsWithChildren, useEffect } from "react";

import { Training } from "../../../contexts/training/domain/training";
import TrainerList from "../TrainerList/TrainerList";

interface PropsTrainingCard extends PropsWithChildren<any> {
  training: Training;
}

const InfoCard: React.FC<PropsTrainingCard> = (props: PropsTrainingCard) => {
  const { training } = props;

  useEffect(() => {
    if (training.color) {
      document.body.style.setProperty("--hover", training.color);
    } else {
      document.body.style.setProperty("--hover", "#A3BCC4");
    }
  }, [training.color]);

  return (
    <div className={"m-training-card"}>
      <TrainerList training={training} />
      <div className={"m-card"}>
        <div className={"m-card__content m-vertical-xl"}>
          <div className={"p-bottom"}>
            <p className={"a-card__paragraph"}>
              Nivel de actividad física:{" "}
              <span
                style={{
                  color: training.color,
                }}
              >
                {training.level}
              </span>
            </p>
            <hr className={"a-separator"} />
            <p className={"a-card__paragraph"}>
              Número de participantes:{" "}
              <span
                style={{
                  color: training.color,
                }}
              >
                {training.capacity}
              </span>
            </p>
          </div>
          <a
            href={
              "https://reservaweb.viday.es/#/client/66e197675bfb42f33ec1bd2d"
            }
            target={"_blank"}
            className={"a-button"}
            rel="noreferrer"
          >
            Comienza a entrenar
          </a>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
