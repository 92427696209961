import "./trainer-list.scss";

// import Slider from "infinite-react-carousel";
import * as React from "react";
import { PropsWithChildren } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import { Author } from "../../../contexts/blog/domain/author";
import { Training } from "../../../contexts/training/domain/training";

interface PropsTrainingCard extends PropsWithChildren<any> {
  training: Training;
}

const TrainerList: React.FC<PropsTrainingCard> = (props: PropsTrainingCard) => {
  const { training } = props;
  const trainers_render = [];

  training.trainers.forEach((trainer: Author, index) => {
    trainers_render.push(
      <div className={"a-trainers__trainer"} key={index}>
        {trainer.name}
      </div>
    );
  });

  return (
    <ScrollContainer className="scroll-container m-trainer-list">
      <div className={"scroll-container__content"}>
        <div className={"a-trainers"}>Entrenadores:</div>
        {trainers_render}
      </div>
    </ScrollContainer>
    // <Slider
    //   slidesToShow={2.5}
    //   arrows={false}
    //   className={"m-list-training-slider"}
    // >
    //   <div className={"a-trainers"}>Entrenadores:</div>
    //   {trainers_render}
    // </Slider>
  );
};

export default TrainerList;
