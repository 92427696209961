import "./training.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { PageContext } from "gatsby/internal";
import * as React from "react";

import Reviews from "../../components/GMB/Reviews";
import OthersMarkupSchema from "../../components/SEO/OthersMarkupSchema";
import { SEO } from "../../components/SEO/SEO";
import { getOtherServiceMapped } from "../../contexts/othersServices/infrastructure/otherServiceSanityDataMapper";
import { getTechniqueMapped } from "../../contexts/techniques/infrastructure/techniqueSanityDataMapper";
import { Training } from "../../contexts/training/domain/training";
import Banner from "../../views/shared/banner/Banner";
import Hero from "../../views/shared/hero/Hero";
import Layout from "../../views/shared/layout/layout";
import WeTechniques from "../../views/techniques/slider/WeTechniques";
import InfoCard from "../../views/training/InfoCard/InfoCard";
import TrainerCard from "../../views/training/TrainerCard/TrainerCard";
import TrainingPlan from "../../views/training/TrainingPlan/TrainingPlan";
import TrainingPricesCard from "../../views/training/TrainingPricesCard/TrainingPricesCard";

interface TrainingPageContext extends PageContext {
  training: Training;
  id: string;
}

const PageTraining: React.FC<TrainingPageContext> = (
  props: TrainingPageContext
) => {
  const { training } = props.pageContext;

  const { projectId, dataset } = props.data.site.siteMetadata;

  // const otherTrainings: Training[] = [];
  // const techniques: Technique[] = [];

  const services = [];

  props.data.allSanityTechnique.nodes.forEach((_technique) => {
    services.push(getTechniqueMapped(_technique));
  });
  props.data.allSanityOtherServices.nodes.forEach((_service) => {
    services.push(getOtherServiceMapped(_service));
  });
  // props.data.allSanityTraining.nodes.forEach((_training) => {
  //   otherTrainings.push(getTrainingMapped(_training));
  // });

  // Search for prices in each pack
  let isPrices = false;
  if (training.packs) {
    training.packs.forEach((pack) => {
      if (pack.priceOffer) {
        isPrices = true;
      }
    });
  }

  return (
    <Layout>
      <OthersMarkupSchema
        service={training.seo.title}
        description={training.seo.description}
        image={training.photo.asset.gatsbyImageData.images.fallback.src}
        slug={training.shortTitle}
      />
      <SEO
        title={`${training.seo.title}`}
        description={training.seo.description}
        canonical={training.slug}
        image={training.photo.asset.gatsbyImageData.images.fallback.src}
      />
      <div className={"t-training"}>
        <Hero
          title={`${training.title}`}
          subtitle={`${training.subtitle}`}
          minutes={training.minutes}
          photo={training.background.asset.gatsbyImageData.images.fallback.src}
          photo_alt={training.background_alt}
          color={training.color}
          isMinutes={true}
        >
          <a
            href={
              "https://reservaweb.viday.es/#/client/66e197675bfb42f33ec1bd2d"
            }
            target={"_blank"}
            className={"a-button"}
            rel="noreferrer"
          >
            Reserva
          </a>
        </Hero>
      </div>
      <InfoCard training={training} />
      <section className={"o-training-info"}>
        <div
          className={
            "container o-box is-border-top-right-desktop is-border-top-right-mobile grid-x2 grid-x2--break-pro"
          }
          style={{
            backgroundColor: `${training.color}`,
          }}
        >
          <div
            className={"a-box__image is-border-top-right-mobile"}
            style={{
              backgroundImage: `url(${training.photo.asset.gatsbyImageData.images.fallback.src})`,
            }}
          />
          <div className={"m-box is-border-top-right-desktop"}>
            <BlockContent
              blocks={training.content}
              projectId={projectId}
              dataset={dataset}
            />
          </div>
        </div>
      </section>
      <TrainerCard training={training} />
      <TrainingPlan training={training} />
      <>
        {isPrices && (
          <section className={"t-training"}>
            <TrainingPricesCard packs={training.packs} color={training.color} />
          </section>
        )}
      </>
      <Banner
        title={"Tu mejor forma con Obelion Training"}
        background={
          training.background.asset.gatsbyImageData.images.fallback.src
        }
        color={training.color}
        isOverflow={isPrices}
      >
        <a
          href={"https://reservaweb.viday.es/#/client/66e197675bfb42f33ec1bd2d"}
          target={"_blank"}
          className={"a-button"}
          rel="noreferrer"
          style={{
            backgroundColor: `${training.color}`,
          }}
        >
          Comienza a entrenar ahora
        </a>
      </Banner>
      <WeTechniques services={services} title={"Otros servicios Obelion"} />
      <Reviews />
    </Layout>
  );
};
export default PageTraining;

export const query = graphql`
  query templateTrainingQuery(
    $id: String = "700cc519-6fc2-41e0-9e15-e8f81748715b"
  ) {
    allSanityTraining(filter: { _id: { ne: $id } }) {
      nodes {
        ...Training
      }
    }
    allSanityTechnique {
      nodes {
        ...Technique
      }
    }
    allSanityOtherServices {
      nodes {
        ...OtherService
      }
    }
    site {
      siteMetadata {
        projectId
        dataset
      }
    }
  }
`;
