import "./trainer-card.scss";

import BlockContent from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { createRef, PropsWithChildren, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import ScrollContainer from "react-indiana-drag-scroll";

import { Author } from "../../../contexts/blog/domain/author";
import { Training } from "../../../contexts/training/domain/training";

interface PropsTrainingCard extends PropsWithChildren<any> {
  training: Training;
}

const TrainerCard: React.FC<PropsTrainingCard> = (props: PropsTrainingCard) => {
  const { training } = props;
  const trainers_render = [];
  const navigation_render = [];
  const sliderTrainers = createRef(null);
  let withCard;

  useLayoutEffect(() => {
    function resize() {
      withCard = document.getElementsByClassName("m-trainer__container")[0]
        .clientWidth;
    }
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const move = (moveLong: number) => {
    const rdSlider = ReactDOM.findDOMNode(sliderTrainers.current);
    rdSlider.scrollTo(moveLong * withCard, 0);
  };

  training.trainers.forEach((trainer: Author, index) => {
    const roles_render = [];
    trainer.roles.forEach((role) => {
      roles_render.push(
        <span id={role.id} key={`${role.id}-${trainer.id}`}>
          {role.name} {" | "}
        </span>
      );
    });
    navigation_render.push(
      <span
        onClick={() => {
          move(index);
        }}
        key={`nav-${trainer.name}`}
        style={{
          color: `${training.color}`,
        }}
      >
        O
      </span>
    );
    trainers_render.push(
      <div key={index} className={"m-trainer__container"} id={trainer.name}>
        <div className={"m-trainer__card"}>
          <div className={"m-training__photo-socials"}>
            <div className={"is-relative"}>
              <SanityImage
                {...trainer.photo}
                alt={trainer.name}
                className={"a-image--rounded a-trainer__photo"}
              />
              <div className={"socials"}>
                {trainer.socials && trainer.socials.instagram && (
                  <a
                    href={trainer.socials.instagram}
                    target={"_blank"}
                    rel={"nofollow noopener noreferrer"}
                  >
                    <svg
                      className={"a-social-icon"}
                      x="0px"
                      y="0px"
                      viewBox="0 0 448 512"
                    >
                      <path
                        style={{ fill: training.color }}
                        d="M224.1,141c-63.6,0-114.9,51.3-114.9,114.9s51.3,114.9,114.9,114.9S339,319.5,339,255.9S287.7,141,224.1,141z
                         M224.1,330.6c-41.1,0-74.7-33.5-74.7-74.7s33.5-74.7,74.7-74.7s74.7,33.5,74.7,74.7S265.2,330.6,224.1,330.6L224.1,330.6z
                         M370.5,136.3c0,14.9-12,26.8-26.8,26.8c-14.9,0-26.8-12-26.8-26.8s12-26.8,26.8-26.8S370.5,121.5,370.5,136.3z M446.6,163.5
                        c-1.7-35.9-9.9-67.7-36.2-93.9c-26.2-26.2-58-34.4-93.9-36.2c-37-2.1-147.9-2.1-184.9,0C95.8,35.1,64,43.3,37.7,69.5
                        s-34.4,58-36.2,93.9c-2.1,37-2.1,147.9,0,184.9c1.7,35.9,9.9,67.7,36.2,93.9s58,34.4,93.9,36.2c37,2.1,147.9,2.1,184.9,0
                        c35.9-1.7,67.7-9.9,93.9-36.2c26.2-26.2,34.4-58,36.2-93.9C448.7,311.3,448.7,200.5,446.6,163.5L446.6,163.5z M398.8,388
                        c-7.8,19.6-22.9,34.7-42.6,42.6c-29.5,11.7-99.5,9-132.1,9s-102.7,2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6
                        c-11.7-29.5-9-99.5-9-132.1s-2.6-102.7,9-132.1c7.8-19.6,22.9-34.7,42.6-42.6c29.5-11.7,99.5-9,132.1-9s102.7-2.6,132.1,9
                        c19.6,7.8,34.7,22.9,42.6,42.6c11.7,29.5,9,99.5,9,132.1S410.5,358.6,398.8,388z"
                      />
                    </svg>
                  </a>
                )}
                {trainer.socials && trainer.socials.twitter && (
                  <a
                    href={trainer.socials.twitter}
                    target={"_blank"}
                    rel={"nofollow noopener noreferrer"}
                  >
                    <svg
                      className={"a-social-icon"}
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                    >
                      <path
                        style={{ fill: training.color }}
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                      />
                    </svg>
                  </a>
                )}
                {trainer.socials && trainer.socials.facebook && (
                  <a
                    href={trainer.socials.facebook}
                    target={"_blank"}
                    rel={"nofollow noopener noreferrer"}
                  >
                    <svg
                      className={"a-social-icon"}
                      x="0px"
                      y="0px"
                      viewBox="0 0 448 512"
                    >
                      <path
                        style={{ fill: training.color }}
                        d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                      />
                    </svg>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className={"m-trainer__footer"}>
            <h2 className={"a-trainer__name"}>{trainer.name}</h2>
            <p className={"a-trainer__role"}>| {roles_render}</p>
            <BlockContent blocks={trainer.biography} />
          </div>
        </div>
      </div>
    );
  });
  // function next() {
  //   if (refSlider && refSlider.current) {
  //     refSlider.current.slickPrev();
  //   }
  // }
  // function prev() {
  //   if (refSlider && refSlider.current) {
  //     refSlider.current.slickNext();
  //   }
  // }

  useLayoutEffect(() => {
    document.body.style.setProperty("--button-bg", training.color);
  }, []);

  return (
    <div className={"o-trainers"}>
      <div className="m-navigation-slider">{navigation_render}</div>
      <ScrollContainer className="scroll-container" ref={sliderTrainers}>
        <div className={"scroll-container__content"}>{trainers_render}</div>
      </ScrollContainer>
    </div>
  );
};

export default TrainerCard;
