import "./training-plan.scss";

// import Slider from "infinite-react-carousel";
import * as React from "react";
import { createRef, PropsWithChildren, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import ScrollContainer from "react-indiana-drag-scroll";

import { Training } from "../../../contexts/training/domain/training";

interface PropsTrainingCard extends PropsWithChildren<any> {
  training: Training;
}

const TrainingPlan: React.FC<PropsTrainingCard> = (
  props: PropsTrainingCard
) => {
  if (!props.training?.list?.list) return <></>;
  const { training } = props;
  const planList = training.list.list;

  const list_render = [];
  const numbers_render = [];

  const sliderPlan = createRef(null);
  let withCard;

  useLayoutEffect(() => {
    function resize() {
      withCard =
        document.getElementsByClassName("m-plan-cards__card")[0].clientWidth;
    }
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const move = (moveLong: number) => {
    const rdSlider = ReactDOM.findDOMNode(sliderPlan.current);
    rdSlider.scrollTo(moveLong * withCard, 0);
  };

  planList.list.forEach((benefit, index) => {
    numbers_render.push(
      <span
        onClick={() => {
          move(index);
        }}
        style={{
          color: `${training.color}`,
        }}
        key={`nav-${index}`}
      >
        {index + 1}
      </span>
    );
    list_render.push(
      <div
        className={"m-plan-cards__card"}
        key={`plan-${index}`}
        id={`${index}`}
      >
        <div className={"is-flex"}>
          <span
            className={"a-benefit-number"}
            style={{
              color: `${training.color}`,
            }}
          >
            0{benefit.number}
          </span>
          <h3 className={"a-benefit-title"}>{benefit.title}</h3>
        </div>
        <p>{benefit.description}</p>
      </div>
    );
  });
  list_render.push(<div className={"is-fake"} key={"empty-plan"}></div>);
  return (
    <div className={"o-training-plan"}>
      <h2 className={"a-plan-title"}>{planList.title}</h2>
      <div className="m-navigation-slider is-hidden-min-desktop">
        {numbers_render}
      </div>
      <ScrollContainer className="scroll-container" ref={sliderPlan}>
        <div className={"scroll-container__content m-plan-cards"}>
          {list_render}
        </div>
      </ScrollContainer>
    </div>
  );
};

export default TrainingPlan;
